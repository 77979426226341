import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/components/AppRouter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/vercel/path0/app/context/AuthContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoadingProvider"] */ "/vercel/path0/app/context/LoadingContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModalProvider"] */ "/vercel/path0/app/context/ModalContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserProvider"] */ "/vercel/path0/app/context/UserContext.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Noto_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"700\"]}],\"variableName\":\"notoSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/vercel/path0/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-toastify/dist/ReactToastify.css");
